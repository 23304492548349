
import './App.css';
import HomeRoot from './Root/HomeRoot';
import { BrowserRouter as Router } from 'react-router-dom'
import { createStore } from 'redux';
import { reducer } from './Store/reducer'
import { Provider } from 'react-redux';
import { Suspense } from 'react';
import Loaders from './Loaders';

const store = createStore(reducer)

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loaders />}>
        <Router>
          <div className="font h-100" style={{ fontFamily: 'Graphik, Helvetica, Arial, sans-serif' }}>
            <HomeRoot />
          </div>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
