import { SET_LOGGEDIN, SET_TOKEN, SET_USER_DETAILS, SET_MENU_ITEMS, SET_ORG_ID } from "./constants";
import authStore from "./stores";

const authReducers = (state = authStore, action) => {
    switch (action.type) {
        case SET_LOGGEDIN:
            return { ...state, isLoggedIn: action.payload }
        case SET_TOKEN:
            return { ...state, token: action.payload }
        case SET_USER_DETAILS:
            return { ...state, userDetails: action.payload }
        case SET_MENU_ITEMS:
            return { ...state, menuItems: action.payload }
        case SET_ORG_ID:
            return { ...state, orgId: action.payload }
        default:
            return state
    }
}

export default authReducers