
import Home from "./Home"
import Services from './Services'
import Missions from "./Missions"
import Contacts from "./Contacts"

export const CombinedRoutes = [
    {
        name:'Home',
        link:'/',
        component:Home
    },
    {
        name:'services',
        link:"/services",
        component:Services
    },
    {
        name:"mission",
        link:"/mission",
        component:Missions
    },
    {
        name:'Contacts',
        link:'/contacts',
        component:Contacts
    }
]