import { motion } from 'framer-motion'
import React from 'react'
import briefExpJSON from './assets/briefExp.json'
import missionJSON from './assets/mission.json'
import visionJSON from './assets/vision.json'
import Lottie from 'lottie-react-web';
import { FiPhoneCall } from 'react-icons/fi'

const Missions = () => {
    return (
        <motion.div className="min-h-screen relative flex flex-col">
            <section id="brief" className="bg-[#FFF3E9]">
                <motion.div className="px-5 mt-20 mb-20 grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2">
                    <motion.div className="flex flex-col text-black font-bold text-start justify-center">
                        <motion.div className="text-xl text-[gray] flex-row flex">
                            Who we are and What we are in doing in
                        </motion.div>
                        <motion.div className="text-xl">
                            <p className="text-[#0477A8] mr-1 ml-1">Solvintel</p>
                        </motion.div>
                        <motion.div className="text-md font-thin indent-8 leading-loose mt-2 mb-2">
                            Our company operates as a hybrid model, offering both service-based and product-based solutions. As a service-based company, we provide tailored services and expertise to clients, addressing their specific needs and challenges. Simultaneously, we develop innovative products that cater to broader market demands, leveraging our expertise and technologies. This dual approach allows us to deliver customized solutions while also offering scalable products to a wider audience, ensuring comprehensive value for our clients and stakeholders.
                        </motion.div>
                        <motion.div className="bg-[#FFF] text-black font-medium px-5 py-4 flex flex-row justify-start w-52 rounded-full">
                            <FiPhoneCall className='mt-1 mr-2 ml-2' /><p className='mr-2 ml-2'>Contact Us</p>
                        </motion.div>
                    </motion.div>
                    <motion.div style={{ height: '90%', width: '90%' }}>
                        <Lottie
                            options={{
                                animationData: briefExpJSON,
                                loop: true, // Set it to false if you don't want the animation to loop
                                autoplay: true, // Set it to false if you don't want the animation to play automatically
                            }}
                        />
                    </motion.div>
                    {/* Our company's mission is to empower individuals and businesses through innovative technology solutions. We are dedicated to delivering exceptional value by leveraging cutting-edge technologies to solve complex challenges and drive tangible results. With a focus on customer satisfaction and continuous improvement, we strive to be a trusted partner, providing reliable and tailored solutions that enable our clients to thrive in the digital age. */}
                </motion.div>
            </section>
            <section id="missionVision">
                <motion.div className="px-5 mt-20 mb-20 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-3">
                    <motion.div style={{ height: '90%', width: '50%' }}>
                        <Lottie
                            options={{
                                animationData: missionJSON,
                                loop: true, // Set it to false if you don't want the animation to loop
                                autoplay: true, // Set it to false if you don't want the animation to play automatically
                            }}
                        />
                    </motion.div>
                    <motion.div className="flex flex-col col-span-2 text-black font-bold text-start justify-center">
                        <motion.div className="text-xl">
                            <p className="text-[#0477A8] mr-1 ml-1">Mission</p>
                        </motion.div>
                        <motion.div className="text-md font-thin indent-8 leading-loose mt-2 mb-2">
                            Our company's mission is to empower individuals and businesses through innovative technology solutions. We are dedicated to delivering exceptional value by leveraging cutting-edge technologies to solve complex challenges and drive tangible results. With a focus on customer satisfaction and continuous improvement, we strive to be a trusted partner, providing reliable and tailored solutions that enable our clients to thrive in the digital age.
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className="px-5 mt-20 mb-20 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-3">
                    <motion.div style={{ height: '90%', width: '50%' }}>
                        <Lottie
                            options={{
                                animationData: visionJSON,
                                loop: true, // Set it to false if you don't want the animation to loop
                                autoplay: true, // Set it to false if you don't want the animation to play automatically
                            }}
                        />
                    </motion.div>
                    <motion.div className="flex flex-col col-span-2 text-black font-bold text-start justify-center">
                        <motion.div className="text-xl">
                            <p className="text-[#0477A8] mr-1 ml-1">Vision</p>
                        </motion.div>
                        <motion.div className="text-md font-thin indent-8 leading-loose mt-2 mb-2">
                            In our vision to be a global leader, we strive to connect people and enable seamless collaboration and communication across geographical boundaries. By embracing the power of technology, we aim to empower businesses and individuals to overcome physical limitations and unlock new possibilities. Through our hybrid model of service-based and product-based offerings, we aim to deliver tailored solutions and scalable products that exceed client expectations.

                            With a relentless focus on innovation and user experience, we aim to create game-changing solutions that solve critical problems and set new industry standards. By combining our expertise, advanced technologies, and deep market insights, we aspire to deliver products that not only meet but exceed customer expectations, paving the way for a more efficient and connected future.
                        </motion.div>
                    </motion.div>
                </motion.div>
            </section>
        </motion.div>
    )
}

export default Missions
