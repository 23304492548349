import React from 'react';
import { motion } from 'framer-motion'
import homeAnimation from './assets/home1st.json'
import Lottie from 'lottie-react-web';
import starlion from './assets/277586751_371219528351313_6306191191547703071_n-removebg-preview.png'
import clients from './assets/Group 34@2x.png'
import countries from './assets/Group 35@2x.png'
import partners from './assets/meeting_609024.png'
import map from './assets/map_854878.png'

const Home = () => {

    return (
        <motion.div className="relative min-h-screen flex flex-col">
            <section id="home" className="home1Feed">
                <motion.div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5">
                    <motion.div className="flex flex-col px-5 text-start justify-center">
                        <motion.div className="flex flex-row text-start">
                            <span className="text-black text-xl font-bold"><span className="text-white">Solvintel</span> a company with intellectual crowd</span>
                        </motion.div>
                        <motion.div className="text-md text-black mt-2">
                            Solvintel is a dynamic company that leverages advanced technologies to deliver exceptional solutions and services to our clients. Our team is globally connected, utilizing state-of-the-art tools to ensure seamless collaboration. Committed to continuous innovation and professional growth, we strive to achieve outstanding results and drive success for our clients.
                        </motion.div>
                        <motion.div className="bg-[white] mt-3 mb-3 py-2 px-2 rounded-3xl text-center text-black w-80 cursor-pointer">
                            Know more about us
                        </motion.div>
                    </motion.div>
                    <motion.div className="lot" style={{ height: '100%', width: '100%' }}>
                        <Lottie
                            options={{
                                animationData: homeAnimation,
                                loop: true, // Set it to false if you don't want the animation to loop
                                autoplay: true, // Set it to false if you don't want the animation to play automatically
                            }}
                        />
                    </motion.div>
                </motion.div>
            </section>
            <section id="brands" className='bg-[#F7EEEE]'>
                <motion.div className="text-black h-96 text-xl px-5">
                    <motion.div className='px-5 py-5 font-bold'>
                        Some of our brand partners
                    </motion.div>
                    <motion.div className="grid grid-cols-2 px-5 py-5 gap-10 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
                        <motion.div className="flex flex-row">
                            <motion.div className="flex flex-col items-center justify-center">
                                <img src={starlion} height={100} width={100} />
                            </motion.div>
                            <motion.div className='flex flex-col mx-5'>
                                <motion.div className='text-xl font-bold'>
                                    Starlions Matric Hr.Sec School
                                </motion.div>
                                <motion.div className='text-sm'>
                                    Empowering education with our advanced ERP software for streamlined operations and enhanced student experiences.
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </section>
            <section id="testimonials" className="bg-[#FDF9EF]">
                <motion.div className="text-black h-42 px-5">
                    <motion.div className='px-5 py-5 font-bold'>
                        Testimonials
                    </motion.div>
                    <motion.div className="grid grid-cols-4 px-5 py-5 gap-10 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-4 lg:grid-cols-4">
                        {/* Clients */}
                        <motion.div className="flex flex-row justify-between rounded-xl h-16 bg-[#FFE0EA]">
                            <motion.div className="flex flex-col justify-center px-5">
                                <motion.div className='text-black text-sm'>
                                    Our Clients
                                </motion.div>
                                <motion.div className='text-[grey] tex-sm'>
                                    10+ Satisfied Clients
                                </motion.div>
                            </motion.div>
                            <motion.div className="flex flex-col justify-center px-5">
                                <img src={clients} height={50} width={50} />
                            </motion.div>
                        </motion.div>
                        {/* Countries */}
                        <motion.div className="flex flex-row justify-between rounded-xl h-16 bg-[#E7F5FF]">
                            <motion.div className="flex flex-col justify-center px-5">
                                <motion.div className='text-black text-sm'>
                                    Countries Visited
                                </motion.div>
                                <motion.div className='text-[grey] tex-sm'>
                                    02 Visited Countries
                                </motion.div>
                            </motion.div>
                            <motion.div className="flex flex-col justify-center px-5">
                                <img src={countries} height={50} width={50} />
                            </motion.div>
                        </motion.div>
                        {/* Brand Partners */}
                        <motion.div className="flex flex-row justify-between rounded-xl h-16 bg-[#E2E2E2]">
                            <motion.div className="flex flex-col justify-center px-5">
                                <motion.div className='text-black text-sm'>
                                    Brand Partners
                                </motion.div>
                                <motion.div className='text-[grey] tex-sm'>
                                    2 Brand Partners
                                </motion.div>
                            </motion.div>
                            <motion.div className="flex flex-col justify-center bg-[#E2E2E2] px-5 rounded-full">
                                <img src={partners} height={50} width={50} className="rounded-full" />
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </section>
        </motion.div>
    )
}

export default Home