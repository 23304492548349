import React from "react"
import { motion } from 'framer-motion'
import logo from '../assets/Solvintel logo final-01.png'
import { CombinedRoutes } from '../routes'
import { Switch, Route, useHistory } from "react-router-dom"
import { FaBell } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { useFormik } from "formik"
import axios from "axios"
import * as Yup from 'yup'
import SubscribeField from "../SubscribeField.js"

const initialFormValues = {
    contactId: ''
}

const HomeRoot = () => {
    const history = useHistory()

    // const baseUrl = 'http://localhost:3000/api'
    const baseUrl = "https://solvintelcore.onrender.com/api"

    const subscribeFormSchema = Yup.object().shape({
        contactId: Yup.string().required("Enter either mobile number or email id"),
    })

    const subscribeForm = useFormik({
        initialValues: initialFormValues,
        validateOnMount: true,
        validationSchema: subscribeFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (values.contactId.split('@').length > 1) {
                let subValues = {
                    email: values.contactId
                }
                axios.post(`${baseUrl}/common/subscribe`, subValues).then((res) => {
                    if (res.status === 200) {
                        subscribeForm.resetForm()
                    }
                })
            } else {
                let subValues = {
                    mobile: values.contactId
                }
                axios.post(`${baseUrl}/common/subscribe`, subValues).then((res) => {
                    if (res.status === 200) {
                        subscribeForm.resetForm()
                    }
                })
            }
        }
    })


    return (
        <motion.div className="min-h-screen relative flex flex-col text-black ">
            <motion.div className="sticky top-bar text-md px-5 text-black grid grid-cols-2 bg-white drop-shadow-lg">
                <motion.div className="cursor-pointer" onClick={() => history.push('/')}>
                    <img src={logo} height={100} width={100} />
                </motion.div>
                <motion.div className="flex flex-row justify-evenly items-center text-md text-black">
                    <motion.div className="cursor-pointer" onClick={() => history.push('/')}>
                        Home
                    </motion.div>
                    <motion.div className="cursor-pointer" onClick={() => history.push('/services')}>
                        Our Services
                    </motion.div>
                    <motion.div className="cursor-pointer" onClick={() => history.push('/mission')}>
                        Who we are
                    </motion.div>
                    <motion.div className="cursor-pointer" onClick={() => history.push('/contacts')}>
                        Get connect with us
                    </motion.div>
                </motion.div>
            </motion.div>
            <motion.div className="m-1 text-left h-full overflow-auto bg-white text-black dark:text-white">
                <Switch>
                    {
                        CombinedRoutes.map(c => (
                            <Route key={c.link} path={c.link} exact component={c.component} />
                        ))
                    }
                </Switch>
            </motion.div>
            <motion.div className="relative inset-x-0 bottom-0 flex flex-col drop-shadow-xl footerFeed">
                <motion.div className="brands px-5 grid grid-cols-3 py-2 sm:grid-cols-1 md:grid-cols-3 gap-5">
                    <motion.div className="logo">
                        <motion.div className="cursor-pointer" onClick={() => history.push('/')}>
                            <img src={logo} height={200} width={200} />
                        </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-col justify-around">
                        <motion.div className="cursor-pointer" onClick={() => history.push('/')}>
                            Home
                        </motion.div>
                        <motion.div className="cursor-pointer" onClick={() => history.push('/services')}>
                            Our Services
                        </motion.div>
                        <motion.div className="cursor-pointer" onClick={() => history.push('/mission')}>
                            Who we are
                        </motion.div>
                        <motion.div className="cursor-pointer" onClick={() => history.push('/contacts')}>
                            Get connect with us
                        </motion.div>
                    </motion.div>
                    <motion.div className="subscribe flex flex-col">
                        <motion.div className="text-xl text-black flex flex-row">
                            <motion.div className="rounded-full p-1 bg-amber-200 drop-shadow-lg mr-2 ml-2"><FaBell className="text-amber-500 text-md" /></motion.div>Subscribe for updates
                        </motion.div>
                        <motion.div className="text-sm text-[#969696] leading-6 w-[320px]">
                            Enter your email address to subscribe to this blog and receive notifications of new posts by email.
                        </motion.div>
                        <motion.div className="text-md mt-3 mb-3">
                            Email or phone
                        </motion.div>
                        <motion.div className="flex w-80 items-center border border-gray-300 rounded-md outline-none">
                            <motion.div className="p-2 bg-white">
                                <MdEmail className="text-[#0477A8]" />
                            </motion.div>
                            <SubscribeField edit={true} dataLoaded={true} fieldProps={subscribeForm.getFieldProps('contactId')} fieldHelper={subscribeForm.getFieldHelpers('contactId')} fieldMeta={subscribeForm.getFieldMeta('contactId')} />
                        </motion.div>
                        <motion.div className="bg-[#9A2015] mt-3 mb-3 py-2 px-2 rounded-3xl text-center text-white w-80 cursor-pointer" onClick={() => subscribeForm.submitForm()}>
                            Subscribe Now
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className="flex flex-row flex-1 m-3 items-center justify-center text-center text-md text-black">
                    All copyrights reserved from 2023
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default HomeRoot