import { SET_ALERT, SET_PAGE_TITLE, SET_SIDEBAR } from "./constants";
import themeStore from "./stores";

const themeReducers = (state = themeStore, action) => {
    switch (action.type) {
        case SET_ALERT:
            return { ...state, alert: action.payload }
        case SET_SIDEBAR:
            return { ...state, sidebar: action.payload }
        case SET_PAGE_TITLE:
            return { ...state, pageTitle: action.payload }
        default:
            return state
    }
}

export default themeReducers