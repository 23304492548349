import React from "react"
import { motion } from 'framer-motion'
import contactmap from './assets/contactmap.jpg'
import { MdLocalPhone, MdEmail, MdMessage } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { FaWhatsapp } from "react-icons/fa";
import FieldInput from "./FieldInput";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAlert } from "./Store/Theme/actions";

const initialFormValues = {
    userName: '',
    mobile: '',
    mail: '',
    message: ''
}

const Contacts = () => {

    // const baseURL = "http://localhost:3000/api"
    const baseURL = "https://solvintelcore.onrender.com/api"
    const dispatch = useDispatch()

    const contactFormSchema = Yup.object().shape({
        userName: Yup.string().required("User name is required"),
        mobile: Yup.string().required("Mobile number is required"),
        mail: Yup.string().required("Email is required"),
        message: Yup.string().required("Message is required")
    })

    const contactForm = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: contactFormSchema,
        onSubmit: (values) => {
            axios.post(`${baseURL}/common/enquiry`, values).then((res) => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: 'Success', message: 'Enquiry submitted successfully', active: true, type: 'success' }))
                    contactForm.resetForm()
                }
            })
        }
    })

    return (
        <motion.div className="overflow-hidden relative flex flex-col">
            <motion.div className="absolute opacity-10 flex flex-col w-full h-full">
                <img src={contactmap} height={50} />
            </motion.div>
            <motion.div className="relative flex flex-col">
                <motion.div className="text-black items-center w-full flex flex-row justify-center text-center">
                    <p className="font-bold text-[24px] flex flex-row">Thanks for choosing us and get connected with Solv<span className="text-[#D63465]">int</span>el</p>
                </motion.div>
                <motion.div className="text-black items-center w-full flex flex-row justify-center text-center">
                    <p className="font-bold text-[24px] flex flex-row"><MdLocalPhone className="mt-1.5" /> &nbsp; Phone &nbsp;<span className="text-[#D63465]">+91 8754-292497</span></p>
                </motion.div>
                <motion.div className="text-black items-center w-full flex flex-row justify-center text-center">
                    <p className="font-bold text-[14px] flex flex-row"><MdEmail className="mt-1" /> &nbsp; Email &nbsp;<span className="text-[#D63465]">info@solvintel.com</span></p>
                </motion.div>
            </motion.div>
            <motion.div className="relative my-5 flex flex-row w-full justify-center items-center text-center">
                <motion.div className="relative w-full gap-10 px-40 grid grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 h-fit flex flex-row">
                    <motion.div className="flex flex-col">
                        <FieldInput injectClass={'bg-white'} placeholder={'Enter User Name'} label={'User Name'} fieldHelper={contactForm.getFieldHelpers('userName')} fieldProps={contactForm.getFieldProps('userName')} fieldMeta={contactForm.getFieldMeta('userName')} edit={true} dataLoaded={true} />
                        <FieldInput injectClass={'bg-white'} placeholder={'Enter Mobile Number'} label={'Mobile'} fieldHelper={contactForm.getFieldHelpers('mobile')} fieldProps={contactForm.getFieldProps('mobile')} fieldMeta={contactForm.getFieldMeta('mobile')} edit={true} dataLoaded={true} />
                        <FieldInput injectClass={'bg-white'} placeholder={'Enter Email Id'} label={'Mail'} fieldHelper={contactForm.getFieldHelpers('mail')} fieldProps={contactForm.getFieldProps('mail')} fieldMeta={contactForm.getFieldMeta('mail')} edit={true} dataLoaded={true} />
                    </motion.div>
                    <motion.div className="flex flex-col">
                        <FieldInput type="textarea" injectClass={'h-[250px]'} label={'Message'} fieldHelper={contactForm.getFieldHelpers('message')} fieldProps={contactForm.getFieldProps('message')} fieldMeta={contactForm.getFieldMeta('message')} edit={true} dataLoaded={true} />
                        <motion.div className="flex flex-row w-full px-5">
                            <motion.div className="bg-[#D63465] w-full px-2 text-center justify-center py-3 mx-2 rounded-[90px] flex flex-row cursor-pointer" onClick={() => contactForm.submitForm()}><span><MdMessage className="mt-1" /></span>&nbsp;Send message</motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default Contacts