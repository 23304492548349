import { motion } from 'framer-motion'
import React from 'react'
import servicesJSON from './assets/services1.json'
import deliveryJSON from './assets/delivery.json'
import capabilityJSON from './assets/capability.json'
import Lottie from 'lottie-react-web';

const Services = () => {
    return (
        <motion.div className="min-h-screen relative flex flex-col">
            <section id="services" className="bg-[#DDFEFD]">
                <motion.div className="px-5 grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2">
                    <motion.div style={{ height: '90%', width: '90%' }}>
                        <Lottie
                            options={{
                                animationData: servicesJSON,
                                loop: true, // Set it to false if you don't want the animation to loop
                                autoplay: true, // Set it to false if you don't want the animation to play automatically
                            }}
                        />
                    </motion.div>
                    <motion.div className="flex flex-col text-black font-bold text-start justify-center">
                        <motion.div className="text-xl text-[gray] flex-row flex">
                            Services of our <p className="text-[skyblue] mr-1 ml-1">Solvintel</p> and how we're approaching
                        </motion.div>
                        <motion.div className="text-xl text-[gray]">
                            our customers
                        </motion.div>
                        <motion.div className="text-md font-thin indent-8 leading-loose mt-2 mb-2">
                            We are a leading company offering comprehensive services in product development, SEO (Search Engine Optimization), and SMMA (Social Media Marketing Agency). With our expertise, we help businesses maximize their online presence and reach their target audience effectively. Our team is dedicated to staying ahead of the curve, constantly exploring new technologies and developments to deliver cutting-edge solutions to our customers. Trust us to provide top-notch services tailored to your specific needs, ensuring your business remains competitive in the ever-evolving digital landscape.
                        </motion.div>
                    </motion.div>
                </motion.div>
            </section>
            <section id="delivery" style={{ width: 'fit-content' }}>
                <motion.div className="px-5 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-3">
                    <motion.div className="flex flex-col col-span-2 text-black font-bold text-start justify-center">
                        <motion.div className="text-xl text-[gray] flex-row flex">
                            On-time Delivery
                        </motion.div>
                        <motion.div className="text-md font-thin indent-8 leading-loose mt-2 mb-2">
                            At our company, we guarantee timely delivery of our products and services, without any delays. We pride ourselves on utilizing the most effective project management techniques to ensure efficient execution and completion of projects. Our streamlined approach allows us to meet deadlines and deliver exceptional results to our customers. Rest assured, we prioritize timeliness and adhere to strict project timelines, providing you with a reliable and punctual experience.
                        </motion.div>
                    </motion.div>
                    <motion.div style={{ height: '90%', width: '90%' }}>
                        <Lottie
                            options={{
                                animationData: deliveryJSON,
                                loop: true, // Set it to false if you don't want the animation to loop
                                autoplay: true, // Set it to false if you don't want the animation to play automatically
                            }}
                        />
                    </motion.div>
                </motion.div>
            </section>
            <section id="capability">
                <motion.div className="px-5 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-3">
                    <motion.div style={{ height: '90%', width: '90%' }}>
                        <Lottie
                            options={{
                                animationData: capabilityJSON,
                                loop: true, // Set it to false if you don't want the animation to loop
                                autoplay: true, // Set it to false if you don't want the animation to play automatically
                            }}
                        />
                    </motion.div>
                    <motion.div className="flex flex-col col-span-2 text-black font-bold text-start justify-center">
                        <motion.div className="text-xl text-[gray] flex-row flex">
                            Compatibility and Extendability
                        </motion.div>
                        <motion.div className="text-md font-thin indent-8 leading-loose mt-2 mb-2">
                            As a leading software company, we take pride in our compatibility and extendability to meet the diverse needs of our customers. Our software solutions are designed with utmost flexibility, allowing seamless integration with existing systems and infrastructure. Whether you're using different platforms, databases, or operating systems, our software is built to adapt and interact flawlessly.

                            With our extendable software architecture, we ensure that our solutions can easily accommodate future enhancements and scale alongside your business growth. As your requirements evolve, our software can be readily expanded and customized to meet your changing needs. This adaptability saves you time and resources, providing a long-term solution that can evolve with your business.

                            We understand that every customer has unique requirements, and our software is crafted to be highly configurable. From customizing workflows to adding new modules, our solutions can be tailored precisely to align with your specific business processes and objectives.

                            Moreover, our dedicated team of experts works closely with our customers to understand their goals and challenges. We provide comprehensive support, ensuring a smooth integration process and offering ongoing assistance to maximize the value and performance of our software.
                        </motion.div>
                    </motion.div>
                </motion.div>
            </section>
        </motion.div>
    )
}

export default Services